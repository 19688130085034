
/*
* Authentication-related API calls.
*/
import { Auth } from 'aws-amplify';

const functions = {
   // Login user, store token
   async login(params) {
      if(!params.body.username || !params.body.password) {
         console.log("login missing parameters")
      }
      try {
         // Call amplify signin with credentials
         await Auth.signIn(params.body.username, params.body.password)
         // Trigger callback
         if(params.callback) params.callback()
      } catch(error) {
         console.log('error signing in', error)
      }
   },
   // Logout user
   async logout() {
      try {
         await Auth.signOut()
      } catch(error) {
         console.log('error signing out', error)
      }
   },
}

export default functions

<template>
   <div id="header">
      <div class="flex-grid">
         <div class="col-md">
            <img :src="logo"/>
            <h1>Pelican</h1>
            <span>Managers</span>
         </div>
         <div class="col-xs">
            <DateStamp
               class="muted"
               position="top"
            />
         </div>
         <button
            v-if="showLogout"
            v-on:click="logoutCallback"
            class="small-button ultra-light-bg"
         >
            Logout
         </button>
      </div>
      <hr class="dark-bg"/>
   </div>
</template>

<script>
   // Import functions
   import LocalAuth from '@/api/auth.js'
   // Import components
   import DateStamp from '@/components/DateStamp.vue'
   import logoImage from '@/assets/images/logo.png'
   // Export component
   export default {
      name: 'Header',
      props: {
         logo: {
           type: String,
           default: logoImage
         },
         showLogout: {
            type: Boolean,
            default: true
         }
      },
      components: {
         "DateStamp": DateStamp,
      },
      methods: {
         logoutCallback(e) {
            e.preventDefault()
            // Call amplify logout
            LocalAuth.logout()
         }
      }
   }
</script>

<style scoped>
   h1 {
      display: inline-block;
      font-size: 2.8em;
      margin-bottom: 0;
   }
   span {
      margin-left: 1em;
      font-size: 1.2em;
   }
   hr {
      margin-top: 0.5em;
      margin-bottom: 1em;
   }
   #header img {
      display: inline-block;
      max-height: 4em;
      margin-left: 1em;
      margin-right: 0.3em;
      position: relative;
      top: 0.6em;
   }
   #header .small-button {
      position: absolute;
      bottom: 1em;
      right: 1.5em;
   }
</style>

<template>
   <div
      id="dateStamp"
      :class="position"
   >
      {{ date }}
   </div>
</template>

<script>
   const date = new Date()
   const dateString =
      `
         ${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}
      `
   export default {
      name: 'DateStamp',
      props: {
         date: {
            type: String,
            default: dateString
         },
         position: {
            type: String,
            default: 'bottom'
         }
      }
   }
</script>

<style scoped>
   #dateStamp {
      display: inline-block;
      position: absolute;
      right: 1.5em;
      font-size: 0.9em;
   }
   .bottom {
      bottom: 1em;
   }
   .top {
      top: 1em;
   }
</style>

<template>
   <div
      id="footer"
      class="watermark"
   >
      &copy; Fruitbat {{ new Date().getFullYear() }}
   </div>
</template>

<script>
   export default {
      name: 'CopyrightFooter',
      props: {
      },
   }
</script>

<style scoped>
   #footer {
      font-size: 0.8em;
      text-align: center;
      width: 97%;
      border-top-width: 1px;
      border-top-style: solid;
      margin: 2.5em auto 0;
      padding: 1em 0;
   }
</style>
